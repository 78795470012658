import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import BackgroundImage from "gatsby-background-image"
import { CheckCircleIcon } from "@heroicons/react/outline"
import Similar from "../templates/property/Similar"

const ThankYou = ({ data, global }) => {
  const location = useLocation()
  const property = location.state?.property;
  
  return (
  <>
    <BackgroundImage
      Tag="main"
      className="min-h-screen px-4 mx-auto text-center bg-top bg-cover sm:bg-top py-36 sm:px-6 lg:px-8 lg:py-48"
      fluid={data.image.fluid}
      backgroundColor="#072837"
    >
      <CheckCircleIcon className="mx-auto w-12 h-12 text-gray-600" aria-hidden="true" />
      <h1 className="mt-4 text-4xl font-bold text-gray-600 font-headline sm:text-5xl">
        {data.heading}
      </h1>
      <p className="mt-3 text-2xl text-gray-600">
        {data.subtitle}
      </p>
      <p className="mt-3 text-lg text-gray-600">
        {data.instructions}
      </p>
      <div className="flex justify-center">
      <Link
        to={data.ctaLink}
        className="inline-flex items-center px-4 py-2 mt-6 mx-2 text-sm font-medium text-white bg-black bg-opacity-75 border border-transparent rounded-md sm:hover:bg-opacity-50 sm:mt-8"
      >
        {data.ctaLabel}
        </Link>
        <Link
        to={data.cta2Link}
        className="inline-flex items-center px-4 py-2 mt-6 mx-2 text-sm font-medium text-white bg-black bg-opacity-75 border border-transparent rounded-md sm:hover:bg-opacity-50 sm:mt-8"
      >
        {data.cta2Label}
        </Link>
      </div>
      {property && (
          <div id="similar-properties" className="mt-28">
            <h2 className="text-center sm:text-4xl font-headline mb-2 text-3xl font-bold text-gray-600">
              You might also like...</h2>
              <hr className="mx-auto border-gray-600 w-24 mt-4 mb-5 border"/>
            <Similar property={property} global={global} />
          </div>
        )}
    </BackgroundImage>
  </>
  )
}

export default ThankYou
